import * as React from "react";
import { useForm } from "react-hook-form";
import Headers from "./Header";
import "./styles.css";

type FormValues = {
  firstName: string;
  lastName: string;
  correo: string;
  celular: number;
  ReactSelect: string;
  viaje: "";
  cuarentena: "";
  contacto: "";
  sintomas: "";
};

export default function App() {
  const { register, handleSubmit } = useForm<FormValues>();
  const onSubmit = (data: FormValues) => alert(JSON.stringify(data));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Headers />

      <p className="p">Estimado paciente:</p>
      <p className="p">Por la seguridad del personal de la clinica asi como de los pacientes, es necesario que la clinica cuente
        con el formulario de bioseguridad de las personas que asistan a la clinica de acuerdo a la cita solicitada.
        Es por ello, que solicitamos llenarlo por la seguridad de todos y todas.</p>

      <input {...register("firstName")} placeholder="Nombres" />

      <input {...register("lastName")} placeholder="Apellidos" />

      <input {...register("correo")} placeholder="Correo electronico" />

      <input {...register("celular")} placeholder="Numero telefonico" />

      <h2 className="h2">Perfil epidemiológico</h2>

      <div role="radio-group">
        <table cellPadding={5} cellSpacing={0}>
      
        <tbody >

				<tr>
					<th colSpan={2}><p className="p">•En los últimos 15 días, ¿ha regresado de algún viaje al extranjero, ya sea por aire, mar o tierra?</p></th>
				</tr>

				<tr>					
					<td>Si<input type="radio" name="viaje" id="viaje" value="si"></input></td>
				</tr>
				<tr>
					<td>No<input type="radio" name="viaje" id="viaje" value="no"></input></td>
        </tr>
        <tr>
        <td><td>Sí su respuesta fue si. ¿De qué país regresó?</td><td><input id="pais" type="text" /></td></td>
        </tr>


				<tr>
					<th colSpan={2}><p className="p">•Si viajó fuera del país, ¿vivió proceso de cuarentena domiciliaria?</p></th>
				</tr>

      <tr>
					<td>Si<input type="radio" name="cuarentena" id="cuarentena" value="si"></input></td>
				</tr>
				<tr>
					<td>No<input type="radio" name="cuarentena" id="cuarente" value="no"></input></td>
        </tr>

				<tr>
					<th colSpan={2}><p className="p">•¿Tuvo contacto cercano con alguien diagnosticado con COVID-19?</p></th>
				</tr>

      <tr>
					<td>Si<input type="radio" name="contacto" id="contacto" value="si"></input></td>
				</tr>
				<tr>
					<td>No<input type="radio" name="contacto" id="contacto" value="no"></input></td>
        </tr>


				<tr>
					<th colSpan={2}><p className="p">•En los ultimos 15 dias ¿ha presentado sintomas como tos seca, fiebre, dolor de garganta, pérdida del olfato o gusto, o dificultad respiratoria; o bien que haya sido sospechoso de covid-19?</p></th>
				</tr>

      <tr>
					<td>Si<input type="radio" name="sintomas" id="sintomas" value="si"></input></td>
				</tr>
				<tr>
					<td>No<input type="radio" name="sintomas" id="sintomas" value="no"></input></td>
        </tr>

				<tr>
					<th colSpan={2}><p className="p">•¿Tiene el esquema completo de vacunación contra el COVID-19?</p></th>
				</tr>

      <tr>
					<td>Si<input type="radio" name="sintomas" id="sintomas" value="si"></input></td>
				</tr>
				<tr>
					<td>No<input type="radio" name="sintomas" id="sintomas" value="no"></input></td>
        </tr>

			</tbody>

      </table>
    </div><input type="submit"></input>
    </form>
  );
}
